body {
    height: 100vh;
    overflow: hidden;
}

h1{
    font-size:16vw;
    font-weight:800;
    margin:0;
}

.LandingPage{

    height: 100vh;
    margin: 0;
}

.toStats{
    position:absolute;
    left: opx;
    bottom: 0px;
    width: 100vw;
    margin-left: -15px;
    height:19vh;
    text-align:center;
    background-color:#3b5998;
    box-shadow: 0 3px 4px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:200; 
}

.NavToStats:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); 
    color:#FFFFFF;

}

.NavToStats{
    text-decoration:none;
    display:inline-block;
    width:100%;
    height:10%;
    font-size: 75px;
    line-height:15vh;
    font-weight:600;
    color:#f1f1f1;
}

.splash{
    height:100%;
    width:27%;
    background-color: #F4F4F4;
    box-shadow: 10px 0px 30px rgba(0,0,0,.4);
    position:absolute;
    z-index:999;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-size:200px;
    font-weight:700;
    color:rgba(0, 0, 0, 0.3);
    overflow:hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guide{
    position:absolute;
    height:100%;
    width:75%;
    background-color:lightblue ;
    left:25%;
    scroll-snap-type:y mandatory;
    overflow-x: hidden;
    overflow-y: scroll;
}

.slide {
    scroll-snap-align:start;
    padding: 15px;
    height: 100vh;
    width: 75vw;
    color:#ffffff;
    position: relative;
}

.slide:nth-of-type(1){
    background-color:#5cb85c;
} 
.slide:nth-of-type(2){
    background-color:#5bc0de;
} 
.slide:nth-of-type(3){
    background-color:#428bca;
} 

::-webkit-scrollbar {
    width: 0px;
    display:hidden;
} 

::-webkit-scrollbar-track {
    background:transparent;
    display:hidden;
    
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
}